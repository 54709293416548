export default {
  error_occurred: "Przepraszamy, wystąpił błąd. Prosimy spróbować za moment.",

  login: "Zaloguj się",
  sign_up: "Zarejestruj się",
  register: "Rejestracja",
  enter_tenant_name: "Wybierz firmę powiązaną z twoim kontem Calendesk.",
  no_account_question: "Nie masz jeszcze konta?",
  do_you_already_have_an_account: "Masz już konto?",
  login_title: "Zaloguj się",
  login_tenant: "Nazwa Twojej firmy",
  company_field_placeholder: "np. Hair&More",
  next: "Dalej",
  register_subtitle: "Utwórz nowe konto",
  register_confirm_title: "Potwierdź swój adres e-mail",
  register_confirm_subtitle:
    "Na podany przez Ciebie w czasie rejestracji adres e-mail, wysłaliśmy wiadomość z linkiem do aktywacji Twojego konta.",
  register_confirm_text: "Aktywuj konto, by uzyskać dostęp do Calendesk.",
  register_data_incorrect:
    "Adres e-mail, który wprowadziłeś, jest już zarejestrowany w systemie Calendesk. Jeśli zapomniałeś hasła, skorzystaj proszę z opcji przypomnienia.",
  resend_activate_email: "Wyślij link aktywacyjny ponownie",
  login_email: "Twój adres e-mail",
  password: "Hasło",
  email_or_password_incorrect:
    "Podany adres e-mail lub hasło są niepoprawne. Jeśli nie pamiętasz hasła, skorzystaj opcji przypomnienia.",
  please_activate_account:
    "Proszę, aktywuj konto. Wysłaliśmy Ci link aktywacyjny na Twój adres e-mail.",
  activate_account_error_message: "Link jest nieważny lub stracił ważność.",
  forgotten_password: "Nie pamiętasz hasła?",
  forgotten_password_title: "Każdy czasami zapomina swojego hasła",
  forgotten_password_text:
    "Podaj adres e-mail, na który mamy wysłać instrukcję dotyczącą zmiany hasła.",
  forgotten_password_button: "Wyślij link do zmiany hasła",
  forgotten_password_success_title: "Sprawdź swoją skrzynkę",
  forgotten_password_success_text:
    "Jeśli adres e-mail istnieje w systemie, to właśnie wysłaliśmy na niego wiadomość z instrukcjami zmiany hasła.",
  reset_password: "Ustaw nowe hasło",
  reset_password_title: "Ustaw nowe hasło",
  reset_password_text: "Wpisz poniżej Twoje nowe hasło.",
  reset_password_text_hint:
    "Hasło powinno składać się z minimum 8 znaków, zawierać wielką literę, cyfrę oraz znak specjalny.",
  reset_password_button: "Zapisz nowe hasło",
  reset_password_fail_text:
    "Hasło nie zostało zmienione. Link prawdopodobnie wygasł.",
  reset_password_success_title: "Hasło zostało zmienione.",
  reset_password_sent_email:
    "Link do ustawienia hasła został wysłany na adres e-mail użytkownika.",
  reset_password_success_text: "Możesz logować się swoim nowym hasłem.",
  reset_password_success_button: "Ok",
  profile: "Profil",
  number_of_bookings: "Liczba rezerwacji",
  number_of_new_users: "Liczba nowych klientów",
  number_of_newsletter_members: "Liczba zapisów do newslettera",
  monthly_divided: "Z podziałem na miesiące",
  area_code: "Kierunkowy",
  other: "Pozostałe",
  or: "lub",

  short_month_1: "STY",
  short_month_2: "LUT",
  short_month_3: "MAR",
  short_month_4: "KWI",
  short_month_5: "MAJ",
  short_month_6: "CZE",
  short_month_7: "LIP",
  short_month_8: "SIE",
  short_month_9: "WRZ",
  short_month_10: "PAŹ",
  short_month_11: "LIS",
  short_month_12: "GRU",

  section_admin_panel: "Panel",
  section_admin_panel_clients: "Panel / Klienci",
  section_admin_panel_newsletters: "Panel / Klienci / Newsletter",
  section_admin_panel_customer_notifications: "Panel / Klienci / Powiadomienia",
  section_admin_panel_profile: "Panel / Profil",
  section_admin_panel_employees: "Panel / Pracownicy",
  section_admin_panel_sales: "Panel / Sprzedaż",
  section_admin_panel_settings: "Panel / Ustawienia",
  section_admin_panel_service: "Panel / Usługi",
  section_admin_panel_plugins: "Panel / Dodatki",
  section_admin_panel_payments: "Panel / Rachunki i płatności",
  section_admin_panel_offer: "Panel / Oferta",
  section_admin_panel_tools: "Panel / Narzędzia",
  section_admin_panel_notifications: "Panel / Powiadomienia",
  simple_store: "Produkty",
  section_admin_subscriptions_list: "Panel / Subskrypcje",
  last_update: "Ostatnia aktualizacja",
  add_client: "Dodaj klienta",
  add_newsletter_record: "Dodaj adres e-mail",
  upload_photo: "Wgraj zdjęcie",
  upload_photo_error_message:
    "Wgrywanie zdjęcia nie powiodło się. Maksymalny rozmiar to 10 MB.",
  upload_photos_error_message:
    "Niektóre zdjęcia mogły nie zostać dodane. Maksymalny rozmiar to 10 MB.",
  get_company_data: "Pobierz dane",
  get_company_data_failed: "Nie udało się pobrać danych.",
  company_name: "Nazwa firmy",
  street_and_number: "Ulica i numer",
  postcode: "Kod pocztowy",
  city: "Miejscowość",
  country: "Kraj",
  send_email_with_password_change:
    "Wysłać wiadomość e-mail z linkiem do ustawienia hasła?",
  client_data: "Dane klienta",
  company_business_activity: "Dane do faktury",
  remove_image: "Usuń zdjęcie",
  no_way_to_undo_action: "Tej akcji nie da się cofnąć!",
  remove_image_warning: "Jesteś pewien, że chcesz usunąć zdjęcie użytkownika?",
  add_booking: "Dodaj rezerwację",
  add_unavailable_booking_slot: "Dodaj blokadę",
  add_tag: "Dodaj etykietę",
  duplicate: "Duplikuj",
  add_to_group: "Dodaj do grupy",
  cancel_booking: "Anuluj rezerwację",
  delete_booking: "Usuń rezerwację",
  delete_booking_failed:
    "Usuwanie wybranej rezerwacji zakończyło się niepowodzeniem",
  cancel_booking_failed:
    "Anulowanie wybranej rezerwacji zakończyło się niepowodzeniem",
  selected_n_positions: "Wybrano @{amount} pozycji",
  remove_multiple_users: "Usuń użytkowników",
  remove_multiple_employees: "Usuń pracowników",
  remove_employee: "Usuń pracownika",
  lock_multiple_users: "Zablokuj użytkowników",
  unlock_multiple_users: "Odblokuj użytkowników",
  lock_multiple_employees: "Zablokuj pracowników",
  unlock_multiple_employees: "Odblokuj pracowników",
  lock_employee: "Zablokuj pracownika",
  unlock_employee: "Odblokuj pracownika",
  user_remove_failed_bookings:
    "Użytkownik ma zaplanowane wizyty lub aktywne subskrypcje. Przed usunięciem musisz je anulować.",
  employee_remove_failed_bookings_or_services:
    "Pracownik ma zaplanowane wizyty lub został już usunięty. Przed usunięciem pracownika musisz anulować jego wizyty.",
  operation_failed:
    "Wystapił błąd podczas wprowadzania zmian. Spróbuj ponownie.",
  accounts_update_succeeded:
    "Pomyślnie zaktualizowano wybrane konta użytkowników",
  accounts_update_failed:
    "Aktualizowanie wybranych kont zakończyło się niepowodzeniem",
  remove_account_warning: "Jesteś pewien, że chcesz usunąć konto użytkownika?",
  remove_accounts_warning:
    "Jesteś pewien, że chcesz usunąć wybrane konta użytkowników?",
  add_group: "Dodaj grupę",
  sort_by: "Posortuj",
  basic_data: "Dane podstawowe",
  public_data: "Uzupełnij dodatkowe informacje o pracowniku",
  remove_category: "Usuń kategorię",
  remove_category_warning: "Czy jesteś pewien, że chcesz usunąć kategorię?",
  services_in_category: "Usługi w kategorii",
  services_already_assigned_in_category:
    "Do kategori są przypisane usługi. Przypisz je do innej kategorii, by kontynuować.",
  assign_service_to_category: "Przypisz usługę do kategorii",
  current_category: "Aktualna kategoria",
  services_assign_failed:
    "Wystąpił błąd przy przypisywaniu wybranych usług do kategorii",
  assign_n_services_to_category: "Przypisz @{n} usług do kategorii:",
  category_order_info:
    "Kolejność, w jakiej ustawisz kategorie i usługi, będzie odwzorowana na stronie internetowej i aplikacji mobilnej klientów.",
  lock_account: "Zablokuj konto",
  remove_account: "Usuń konto",
  fetch_data_failed: "Wystąpił błąd przy pobieraniu danych",
  edit_service: "Edytuj usługę",
  remove_service: "Usuń usługę",
  variants: "Warianty",
  booking_settings: "Ustawienia rezerwacji",
  booking_time_before: "Rezerwacja usługi najpóźniej",
  cancel_time_before: "Anulacja terminu usługi najpóźniej",
  change_time_before: "Zmiana terminu usługi najpóźniej",
  before_visit: "przed wizytą",
  slots_every_checkbox_text: "Pokaż klientom możliwość rezerwacji co",
  break_time_before_checkbox_text:
    "Dodatkowy czas na przerwę przed utworzoną rezerwacją",
  break_time_after_checkbox_text:
    "Dodatkowy czas na przerwę po utworzonej rezerwacji",
  resources_info:
    "Wybierz limitowane zasoby potrzebne w czasie wykonywania usługi. Klienci nie będą mogli zarezerwować wizyty online, jeśli zasoby w innych wizytach się wyczerpią.",
  quantity: "Liczba",
  quantity_pcs: "Liczba (szt.)",
  value_is_too_high:
    "Wpisana wartość jest za duża. Maksymalna wartość to: @{number}.",
  value_is_too_low:
    "Wpisana wartość jest za mała. Minimalna wartość to: @{number}.",
  resource_name: "Nazwa zasobu",
  add_variant: "Dodaj wariant usługi",
  service_active: "Usługa aktywna w kalendarzu klientów",
  category_active_short: "Aktywna",
  category_inactive_short: "Nieaktywna",
  variant_name: "Nazwa wariantu",
  service_blocked: "Usługa zablokowana",
  all_employees: "Wszyscy pracownicy",
  price_in_currency: "Cena w @{currency}",
  no_employees_found:
    "Nie dodano żadnych pracowników. Możesz stworzyć pracownika teraz i przypisać go do tej usługi. Twoi klienci będą mogli wybrać dodanych pracowników przypisanych do tej usługi.",
  add_employee_fail:
    "Wystąpił problem podczas dodawania pracownika, spróbuj ponownie.",
  select_employee_error: "Wybierz przynajmniej jednego pracownika",
  service_update_failed:
    "Aktualizacja usługi nie powiodła się. Spróbuj ponownie.",
  service_creation_failed:
    "Tworzenie usługi nie powiodło się. Spróbuj ponownie.",
  remove_service_warning: "Czy jesteś pewien, że chcesz usunąć usługę?",
  remove_service_failed: "Usuwanie usługi nie powiodło się. Spróbuj ponownie.",
  move: "Przenieś",
  tag_visits: "Wizyty z etykietą",
  services_with_resource: "Usługi z zasobem",
  resource: "Zasób",
  users_in_group: "Klienci w grupie",
  remove_tag_warning: 'Jesteś pewien, że chcesz usunąć etykietę "@{tag}"?',
  remove_tag: "Usuń etykietę",
  edit_tag: "Edytuj etykietę",
  remove_resource_warning:
    'Jesteś pewien, że chcesz usunąć zasób "@{resource}"?',
  remove_resource: "Usuń zasób",
  edit_resource: "Edytuj zasób",
  remove_group_warning: 'Jesteś pewien, że chcesz usunąć grupę "@{group}"?',
  remove_group: "Usuń grupę",
  edit_group: "Edytuj grupę",
  tag_info:
    "Etykiety opisują cechy charakterystyczne dla wizyty klienta. Każda wizyta może być oznaczona przez wiele etykiet.",
  resource_info:
    "Zasoby określają pomieszczenia lub urządzenia potrzebne do wykonania usługi. Np. krzesło, pokój, stół do masażu itp.",
  resource_info_2:
    "Przypisując zasoby do danej usługi, eliminujesz ryzyko powstania problemu, wynikającego z braku sprzętu, potrzebnego do obsłużenia klienta.",
  group_info:
    "Grupy są pomocne w zarządzaniu klientami. Każdy klient może być przypisany do kilku grup.",
  email_notifications: "Powiadomienia e-mail",
  sms_notifications: "Powiadomienia SMS",
  buy_more: "Dokup",
  notifications_count: "Liczba powiadomień",
  pay: "Zapłać",
  notification_first_label: "Pozostało",
  notification_second_label_email: "powiadomień e-mail",
  notification_second_label_sms: "powiadomień SMS",
  notifications_sms_email: "Powiadomienia SMS i e-mail",
  notifications_reminder_first:
    "Powiadomienia przypomną Tobie oraz Twoim klientom o nadchodzących spotkaniach.",
  notifications_reminder_second:
    "Zdecyduj kogo i jakim rodzajem notyfikacji informować.",
  notification: "Powiadomienie",
  notify_tenant: "Pracownik",
  notify_client: "Klient",
  notification_save_success: "Poprawnie zaktualizowano powiadomienia.",
  invoice_data: "Dane do faktury",
  service_categories: "Kategorie usług",
  application: "Aplikacja",
  email: "E-mail",
  currency: "PLN",
  category: "Kategoria",
  categories: "Kategorie",
  service: "Usługa",
  service_list: "Lista usług",
  services: "Usługi",
  employee: "Pracownik",
  employees: "Pracownicy",
  employees_list: "Lista pracowników",
  plugins: "Dodatki",
  packages: "Twoje pakiety",
  plugins_mobile_drafts: "Aplikacja mobilna",
  plugins_mobile_editor: "Edytor aplikacji mobilnej",
  plugins_payments_tpay: "Płatności TPay",
  plugins_payments_stripe: "Płatności Stripe",
  settings_platform: "Platforma",
  notifications: "Powiadomienia",
  profile_company: "Twoja firma",
  profile_account: "Twój profil",
  payments: "Rachunki i płatności",
  settings: "Ustawienia",
  duration: "Czas trwania",
  from: "Od",
  to: "Do",
  filter: "Filtruj",
  close: "Zamknij",
  today: "Dziś",
  tomorrow: "Jutro",
  this_week: "Aktualny tydzień",
  this_month: "Aktualny miesiąc",
  this_year: "Aktualny rok",
  yesterday: "Wczoraj",
  last_week: "Poprzedni tydzień",
  last_month: "Poprzedni miesiąc",
  last_year: "Poprzedni rok",
  clear: "Wyczyść",
  service_name: "Nazwa usługi",
  category_name: "Nazwa kategorii",
  price: "Cena",
  name: "Imię",
  surname: "Nazwisko",
  from_cannot_be_greater_than_to:
    'Godzina "od" nie może być większa niż godzina "do"',
  name_and_surname: "Imię i nazwisko",
  phone: "Telefon",
  field_is_required: "Pole jest wymagane.",
  value_is_no_url: "Wprowadzona wartość nie jest poprawnym adresem URL.",
  value_is_no_url_to_facebook:
    "Wprowadzona wartość nie jest poprawnym adresem URL do profilu na Facebooku.",
  value_is_no_url_to_twitter:
    "Wprowadzona wartość nie jest poprawnym adresem URL do profilu na Twitterze.",
  value_is_no_url_to_instagram:
    "Wprowadzona wartość nie jest poprawnym adresem URL do profilu na Instagramie.",
  value_is_no_url_to_youtube:
    "Wprowadzona wartość nie jest poprawnym adresem URL do profilu na YouTube.",
  value_is_no_domain: "Wprowadzona wartość nie jest domeną.",
  min_n_characters: "Minimalna liczba znaków to @{min}.",
  max_n_characters: "Maksymalna liczba znaków to @{max}.",
  email_is_incorrect: "Adres e-mail jest niepoprawny.",
  page_not_found: "Ooooups! Nie znaleziono szukanej strony :(",
  go_back_to_main_page: "Wróć do strony głównej i spróbuj ponownie.",
  logout: "Wyloguj",
  status: "Status",
  status_approved: "Zaakceptowane",
  status_waiting: "Oczekuje",
  status_payment: "Do zapłaty",
  status_cancelled: "Anulowane",
  status_done: "Odbyła się",
  date: "Data",
  cancel: "Anuluj",
  yes: "Tak",
  no: "Nie",
  date_from: "Data od",
  date_to: "Data do",
  nothing_found_here: "Brak wyników",
  nothing_here: "Brak",
  save: "Zapisz",
  details: "Szczegóły",
  add_image: "Dodaj zdjęcie",
  change: "Zmień",
  remove: "Usuń",
  email_is_taken: "Konto z podanym adresem e-mail już istnieje.",
  dashboard: "Tablica",
  users: "Klienci",
  users_list: "Lista klientów",
  calendar: "Kalendarz",
  add: "Dodaj",
  resources: "Zasoby",
  translations: "Tłumaczenia",
  web_editor: "Edytor strony www",
  websites: "Strony www",
  mobile_app_title: "Aplikacja mobilna",
  preview: "Podgląd",
  roles: "Funkcja",
  role_admin: "Administrator",
  role_manager: "Manager",
  role_employee: "Pracownik (zaufany)",
  role_employee_basic: "Pracownik (ograniczony)",
  permissions: "Uprawnienia",
  admin_access_question: "Pracownik może logować się do panelu Calendesk",
  send_employees_app_link:
    "Wyślij link do aplikacji mobilnej dla pracowników na jego adres e-mail",
  search: "Szukaj",
  add_user: "Dodaj klienta",
  add_employee: "Dodaj pracownika",
  add_category: "Dodaj kategorię",
  edit_category: "Edytuj kategorię",
  add_service: "Dodaj usługę",
  add_resource: "Dodaj zasób",
  bookings_today: "Rezerwacje na dzisiaj",
  bookings_tomorrow: "Rezerwacje na jutro",
  bookings_this_week: "Rezerwacje na ten tydzień",
  newsletter: "Newsletter",
  month_1: "Styczeń",
  month_2: "Luty",
  month_3: "Marzec",
  month_4: "Kwiecień",
  month_5: "Maj",
  month_6: "Czerwiec",
  month_7: "Lipiec",
  month_8: "Sierpień",
  month_9: "Wrzesień",
  month_10: "Październik",
  month_11: "Listopad",
  month_12: "Grudzień",
  monday: "Poniedziałek",
  tuesday: "Wtorek",
  wednesday: "Środa",
  thursday: "Czwartek",
  friday: "Piątek",
  saturday: "Sobota",
  sunday: "Niedziela",
  short_day_monday: "Pon.",
  short_day_tuesday: "Wt.",
  short_day_wednesday: "Śr.",
  short_day_thursday: "Czw.",
  short_day_friday: "Pt.",
  short_day_saturday: "Sob.",
  short_day_sunday: "Nd.",
  refresh: "Odśwież",
  select_date: "Wybierz datę",
  edit: "Edytuj",
  ok: "Ok",
  client: "Klient",
  clients: "Klienci",
  tag: "Etykieta",
  added_tag: "Dodano etykietę",
  added_group: "Dodano grupę",
  added_resource: "Dodano zasób",
  tags: "Etykiety",
  phone_is_invalid: "Numer telefonu jest niepoprawny.",
  birthday: "Urodziny",
  send_reset_password: "Wysłać e-maila z linkiem do ustawienia hasła?",
  user_created_successfully: "Klient został utworzony poprawnie.",
  employee_created_successfully: "Pracownik został utworzony poprawnie.",
  description: "Opis",
  created_calendar_event_correctly: "Dodano wpis do kalendarza.",
  updated_calendar_event_correctly: "Zaktualizowano wpis w kalendarzu.",
  updated_multiple_bookings_correctly: "Zaktualizowano rezerwacje.",
  deleted_multiple_bookings_correctly: "Usunięto rezerwacje.",
  deleted_multiple_unavailable_booking_slots_correctly:
    "Usunięto blokady kalendarza.",
  deleted_service_event_correctly: "Usługa została usunięta.",
  creating_calendar_event_failed:
    "Dodawanie wpisu nie powiodło się, spróbuj ponownie.",
  deleting_service_event_failed:
    "Usuwanie nie powiodło się. Sprawdź, czy nie masz zaplanowanych wizyt dla tej usługi i spróbuj ponownie.",
  updating_calendar_event_failed:
    "Aktualizacja wpisu nie powiodła się, spróbuj ponownie.",
  updating_bookings_failed:
    "Aktualizacja rezerwacji nie powiodła się, spróbuj ponownie.",
  deleting_bookings_failed:
    "Usuwanie rezerwacji nie powiodło się, spróbuj ponownie.",
  block_spot:
    "Wydarzenie niezwiązane z wizytą klienta (blokada kalendarza dla klientów)",
  block: "Blokada",
  unavailable_spot: "Zablokowane",
  id: "ID",
  per_page: "Pozycji na stronie",
  groups: "Grupy",
  group: "Grupa",
  delete: "Usuń",
  facebook: "Link do profilu na Facebooku",
  instagram: "Link do profilu na Instagramie",
  youtube: "Link do profilu na Youtube",
  twitter: "Link do profilu na Twitterze",
  active_accounts: "Aktywne konta",
  inactive_accounts: "Niektywne konta",
  active_account: "Konto aktywne",
  inactive_account: "Konto niektywne",
  visit: "Wizyta",
  loading: "Ładowanie ...",
  created_at: "Utworzono",
  canceled_at: "Anulowano",
  user: "Użytkownik",
  category_active: "Kategoria usług widoczna dla klientów",
  category_inactive: "Kategoria usług niewidoczna dla klientów",
  email_verified_at: "Weryfikacja e-mail",
  email_unverified: "E-mail niezweryfikowany",
  gender: "Płeć",
  gender_male: "Mężczyzna",
  gender_female: "Kobieta",
  gender_other: "Inna",
  tax: "NIP",
  tax_prefix: "Prefix",
  block_account: "Zablokuj konto",
  unblock_account: "Odblokuj konto",
  generate_new_password_automatically:
    "Wyślij link do zmiany hasła na adres e-mail.",
  unblock_account_question: "Czy na pewno chcesz odblokować konto?",
  block_account_question: "Czy na pewno chcesz zablokować konto?",
  delete_user: "Usuń konto",
  delete_users: "Usuń konta",
  user_can_not_be_loaded:
    "Wystapił błąd. Użytkownik nie może zostać załadowany.",
  user_bookings: "Rezerwacje",
  user_newsletter: "Newsletter",
  title: "Tytuł",
  color: "Kolor",
  no_employee_selected_in_calendar:
    "Nie wybrano pracowników. Wybierz ich z listy powyżej, aby zobaczyć kalendarz.",
  client_policy_confirmation_text:
    "Klient potwierdza, że zapoznał się z regulaminem i polityką prywatności.",
  /*
  calendar toolbar translations
   */
  listWeek: "Plan dnia",
  resourceTimeGridDay: "Dzień",
  timeGridWeek: "Tydzień",
  dayGridMonth: "Miesiąc",
  current_week: "Aktualny tydzień",
  current_month: "Aktualny miesiąc",
  /*
  end of calendar toolbar translations
   */
  date_day_from: "Od (dzień)",
  date_hour_from: "Od (godz.)",
  date_day_to: "Do (dzień)",
  date_hour_to: "Do (godz.)",
  created_date_range: "Data utworzenia",
  canceled_date_range: "Data anulowania",
  event_date_range: "Data wydarzenia",
  payment_date_range: "Data opłacenia",
  you_can_add_note_here: "Tutaj możesz dodać prywatną notatkę",
  account_activation_resend_link_succeeded:
    "Link aktywacyjny został wysłany ponownie",
  account_activation_resend_link_failed:
    "Podczas generowania linku wystąpił błąd, spróbuj ponownie",
  calendar_settings: "Ustawienia kalendarza",
  minimal_length_of_calendar_event: "Minimalna długość wydarzenia w kalendarzu",
  hour_divider_interval: "Oznaczenia godzin widoczne co",
  calendar_time_boundaries: "Zakres kalendarza",
  all_bookings: "Wszystkie rezerwacje",
  bookings: "Rezerwacje",
  more: "więcej",
  visit_datetime: "Termin rezerwacji",
  client_names: "Imię i nazwisko klienta",
  waiting: "Oczekująca na akceptację",
  payment: "Wymagana przedpłata",
  approved: "Zaakceptowana",
  cancelled: "Anulowana",
  done: "Zakończona",
  delete_succeeded: "Usunięto pomyślnie",
  delete_failed: "Podczas usuwania wystąpił błąd",
  confirmation_modal_header: "Akcja wymaga potwierdzenia",
  confirmation_modal_warning: "Jesteś pewien, że chcesz kontynuować?",
  change_status: "Zmień status",
  remove_n: "Usuń (@{n})",
  remove_event: "Usuń wydarzenie",
  editor_tool_global_settings_title: "Ustawienia globalne",
  editor_tool_global_settings_subtitle: "Kolor i logo",
  calendesk_file_input_first: "Upuść pliki tutaj lub ",
  calendesk_file_input_second: "wybierz z dysku,",
  calendesk_file_input_third: "aby dodać",
  assets: "Pliki",
  domain_configuration_title: "Konfiguracja domeny",
  domain_configuration_subtitle:
    "Wybierz domenę, pod którą ma być dostępna Twoja strona www",
  domain_configuration_calendesk_domain:
    "Wpisz nazwę subdomeny (aliasu), pod którą będzie dostępna Twoja strona np. „twojafirma.calendesk.com”.",
  domain_configuration_calendesk_domain_2:
    "Wpisz nazwę subdomeny (aliasu), pod którą będzie dostępna Twoja strona np. „twojafirma.calendesk.net”.",
  domain_configuration_own_domain_text:
    "Możesz także dodać własną domenę np. „twojafirma.pl”.",
  domain_configuration_own_domain_add_button: "Dodaj własną domenę",
  domain_configuration_own_domain_edit_button: "Edytuj własną domenę",
  domain_configuration_footer:
    "Twoja strona będzie dostępna pod adresem: @{domain}",
  domain_configuration_form_alias: "Subdomena (alias) np. nazwa Twojej firmy",
  domain_configuration_form_domain: "Domena",
  add_domain_title: "Dodawanie własnej domeny",
  add_domain_verification_error:
    "Domena jest w trakcie weryfikacji. Jeśli wszystko zostało ustawione poprawnie, odczekaj 15 min i spróbuj ponownie.",
  add_domain_verification_success: "Domena działa poprawnie",
  add_domain_first_step_title: "Twoja domena",
  add_domain_first_step_subtitle:
    "Podaj nazwę domeny, którą chcesz połączyć ze swoją stroną",
  add_domain_first_step_domain: "Twoja domena",
  add_domain_first_step_confirm: "Kontynuuj",
  add_domain_second_step_title: "Panel dostawcy domeny",
  add_domain_second_step_subtitle:
    "Zaloguj się do panelu swojego dostawcy domeny, znajdź sekcję ustawień DNS i dodaj dwa nowe rekordy, wyszczególnione w kolejnym kroku.",
  add_domain_second_step_confirm: "Kontynuuj",
  add_domain_third_step_title: "Dodaj nowe rekordy",
  add_domain_third_step_subtitle:
    "Dodaj rekordy domeny, jak poniżej. U niektórych operatorów domeny rekord CNAME może kończyć się kropką.",
  add_domain_third_step_subtitle_dot: "z kropką na końcu",
  add_domain_third_step_confirm: "Kontynuuj",
  add_domain_third_step_dns_name: "Nazwa",
  add_domain_third_step_dns_type: "Typ",
  add_domain_third_step_dns_url: "Adres",
  add_domain_fourth_step_title: "Weryfikacja działania",
  add_domain_fourth_step_subtitle:
    "Po poprawnym ustawieniu domeny zweryfikuj zmiany.",
  add_domain_fourth_step_confirm: "Weryfikuj działanie domeny",
  web_global_logo: "Logotyp",
  web_global_primary_color: "Kolor główny",
  gallery_input_set_image: "Wstaw",
  no_selected_images: "Nie wybrano zdjęć",
  home_about_element_gallery: "Zdjęcia w galerii",
  new_website: "Nowa strona www",
  drafts_table_header_title: "Nazwa szablonu",
  drafts_table_header_last_updated: "Data edycji",
  published_text: "Opublikowana",
  not_published_text: "Nieopublikowana",
  disabled: "Wyłączona",
  publish: "Opublikuj",
  drafts_category_1: "Beauty",
  drafts_category_2: "Fitness",
  drafts_create_website_button: "Stwórz stronę www",
  tool_save_draft: "Zapisz szkic",
  drafts_disable_confirm_dialog_title: "Wyłącz stronę www",
  drafts_disable_confirm_dialog_warning:
    "Jesteś pewien, że chcesz wyłączyć stronę www?",
  drafts_disable_confirm_dialog_inportant_information:
    "Po wyłączeniu Twoja strona nie będzie dostępna online.",
  drafts_remove_confirm_dialog_title: "Usuń szkic",
  drafts_remove_confirm_dialog_warning:
    "Jesteś pewien, że chcesz usunąć szkic?",
  domain_dialog_title: "Adres Twojej strony internetowej",
  domain_dialog_button: "Konfiguruj domenę",
  domain_dialog_domain_text: "Twoja strona będzie wyświetlana pod adresem:",
  editor_domain_error_message: "Wybrana domena jest niedostępna",
  publish_dialog_button: "Wróc do szkiców",
  /*
  ASSETS
   */
  files: "Pliki",
  assets_no_data: "Brak dodanych zdjęć",
  assets_dialog_use_selected_file: "Użyj wybranego pliku",
  assets_dialog_use_selected_files: "Użyj wybranych plików",
  remove_asset: "Usuń plik",
  remove_asset_warning: "Jesteś pewien, że chcesz usunąć @{file}?",
  change_name_asset_title: 'Zmień nazwę pliku "@{file}"',
  change_name_asset_new_name: "Nowa nazwa pliku",
  editor_image: "Edytor zdjęć",
  editor_image_confirm_button: "Zastosuj",
  editor_image_crop_any: "Dowolny",
  editor_image_crop_5_4: "5:4",
  editor_image_crop_7_5: "7:5",
  editor_image_crop_16_9: "16:9",
  editor_image_crop_3_2: "3:2",
  editor_image_crop_square: "Kwadrat",
  editor_image_reset: "Resetuj",
  editor_image_rotate_left: "Obrót 90 w lewo",
  editor_image_rotate_right: "Obrót 90 w prawo",
  editor_image_flip_x: "Odbicie na osi X",
  editor_image_flip_y: "Odbicie na osi Y",

  /*
  Global
   */
  form_is_invalid:
    "Formularz zawiera błędy. Sprawdź podświetlone pola i spróbuj ponownie.",

  /*
  VALIDATIONS
   */
  no_integer: "Wymagany znak numeryczny.",
  no_numeric_character: "Brak znaku numerycznego.",
  no_lowercase_character: "Brak małej litery.",
  no_uppercase_character: "Brak dużej litery.",
  no_special_character: "Brak znaku specjalnego.",

  /*
  SETTINGS PLATFORM
   */
  settings_platform_submit_button: "Zastosuj zmiany",
  c_language_select_label: "Język",
  c_currency_select_label: "Waluta",
  c_time_zone_autocomplete_label: "Strefa czasowa",

  c_language_select_czech: "Czeski",
  c_language_select_german: "Niemiecki",
  c_language_select_english: "Angielski",
  c_language_select_spanish: "Hiszpański",
  c_language_select_french: "Francuski",
  c_language_select_hindi: "Hinduski",
  c_language_select_dutch: "Niderlandzki",
  c_language_select_polish: "Polski",
  c_language_select_portuguese: "Portugalski",
  c_language_select_russian: "Rosyjski",
  c_language_select_slovak: "Słowacki",
  c_language_select_ukrainian: "Ukraiński",
  c_language_select_bulgarian: "Bułgarski",
  c_language_select_greek: "Grecki",
  c_language_select_estonian: "Estoński",
  c_language_select_finnish: "Fiński",
  c_language_select_irish: "Irlandzki",
  c_language_select_croatian: "Chorwacki",
  c_language_select_hungarian: "Węgierski",
  c_language_select_lithuanian: "Litewski",
  c_language_select_latvian: "Łotewski",
  c_language_select_maltese: "Maltański",
  c_language_select_slovenian: "Słoweński",
  c_language_select_albanian: "Albański",
  c_language_select_swedish: "Szwedzki",
  c_currency_select_pln: "Złoty polski (PLN)",
  c_currency_select_eur: "Euro (EUR)",
  c_currency_select_usd: "Dolar amerykański (USD)",
  c_currency_select_gbp: "Funt brytyjski (GBP)",
  c_currency_select_jpy: "Jen japoński (JPY)",
  c_currency_select_aud: "Dolar australijski (AUD)",
  c_currency_select_cad: "Dolar kanadyjski (CAD)",
  c_currency_select_chf: "Frank szwajcarski (CHF)",
  c_currency_select_sek: "Korona szwedzka (SEK)",
  c_currency_select_nzd: "Dolar nowozelandzki (NZD)",
  c_currency_select_brl: "Real brazylijski (BRL)",
  c_currency_select_bgn: "Lew bułgarski (BGN)",
  c_currency_select_czk: "Korona czeska (CZK)",
  c_currency_select_dkk: "Korona duńska (DKK)",
  c_currency_select_hkd: "Dolar hongkoński (HKD)",
  c_currency_select_huf: "Forint węgierski (HUF)",
  c_currency_select_ils: "Nowy szekel izraelski (ILS)",
  c_currency_select_myr: "Ringgit malezyjski (MYR)",
  c_currency_select_mxn: "Peso meksykańskie (MXN)",
  c_currency_select_twd: "Nowy dolar tajwański (TWD)",
  c_currency_select_nok: "Korona norweska (NOK)",
  c_currency_select_php: "Peso filipińskie (PHP)",
  c_currency_select_ron: "Lej Rumuński (RON)",
  c_currency_select_rub: "Rubel rosyjski (RUB)",
  c_currency_select_sgd: "Dolar singapurski (SGD)",
  c_currency_select_thb: "Bat tajlandzki (THB)",
  c_currency_select_uah: "Hrywna ukraińska (UAH)",
  c_currency_select_inr: "Rupia indyjska (INR)",
  c_currency_select_clp: "Chilijskie peso (CLP)",
  c_currency_select_zar: "Rand południowoafrykański (ZAR)",
  c_currency_select_bif: "Frank burundyjski (BIF)",
  c_currency_select_djf: "Frank dżibutyjski (DJF)",
  c_currency_select_gnf: "Frank gwinejski (GNF)",
  c_currency_select_kmf: "Frank komoryjski (KMF)",
  c_currency_select_krw: "Won południowokoreański (KRW)",
  c_currency_select_mga: "Ariary madagaskarski (MGA)",
  c_currency_select_pyg: "Guarani paragwajskie (PYG)",
  c_currency_select_rwf: "Frank rwandyjski (RWF)",
  c_currency_select_ugx: "Szyling ugandyjski (UGX)",
  c_currency_select_vnd: "Dong wietnamski (VND)",
  c_currency_select_vuv: "Vatu wanuackie (VUV)",
  c_currency_select_xaf: "Frank CFA BEAC (XAF)",
  c_currency_select_xof: "Frank CFA BCEAO (XOF)",
  c_currency_select_xpf: "Frank CFP (XPF)",

  /*
  PROFILE COMPANY
   */
  profile_company_name_label_input: "Nazwa firmy",
  profile_company_name_placeholder_input: "np. Hair&More",
  profile_company_contact_header: "Dane kontaktowe",
  profile_company_contact_address_label_autocomplete: "Adres",
  profile_company_contact_email_label_input: "Adres e-mail",
  profile_company_contact_country_autocomplete: "Kraj",
  profile_company_work_hours_header: "Dni i godziny pracy",
  profile_company_social_media_header: "Media społecznościowe",
  profile_company_social_media_facebook_label_input:
    "Link do profilu na Facebooku",
  profile_company_social_media_twitter_label_input:
    "Link do profilu na Twitterze",
  profile_company_social_media_instagram_label_input:
    "Link do profilu na Instagramie",
  profile_company_social_media_youtube_label_input:
    "Link do profilu na Youtube",
  profile_company_regulations_header: "Regulamin",
  profile_company_regulations_label_textarea: "Regulamin",
  profile_company_privacy_policy_header: "Polityka prywatności",
  profile_company_privacy_policy_label_textarea: "Polityka prywatności",
  profile_company_contact_street_label_input: "Ulica i numer",
  profile_company_contact_zip_code_label_input: "Kod pocztowy",
  profile_company_contact_city_label_input: "Miasto",
  plugins_store_all_plugins_tab: "Wszystkie dodatki",

  plugins_mobile_drafts_header_button: "Edytuj aplikację mobilną",
  create_mobile_draft_dialog_title: "Wybierz szablon",
  create_mobile_draft_dialog_name_label_input: "Nazwa szablonu",
  create_mobile_draft_dialog_name_label_input_placeholder: "np. Moja aplikacja",
  create_mobile_draft_dialog_select_category: "Kategoria",
  create_mobile_draft_dialog_submit: "Zapisz i kontynuuj",
  plugins_mobile_drafts_new_application_button: "Nowa aplikacja mobilna",
  plugins_mobile_drafts_disable_confirm_dialog_title:
    "Wyłącz aplikację mobilną",
  plugins_mobile_drafts_disable_confirm_dialog_warning:
    "Jesteś pewien, że chcesz wyłączyć aplikację mobilną?",
  plugins_mobile_drafts_disable_confirm_dialog_important_information:
    "Po wyłączeniu Twoja aplikacja nie będzie dostępna online.",
  plugins_mobile_drafts_remove_confirm_dialog_title: "Usuń szkic",
  plugins_mobile_drafts_remove_confirm_dialog_warning:
    "Jesteś pewien, że chcesz usunąć szkic?",
  plugins_mobile_preview_information_title: "Podgląd zmian na telefonie",
  plugins_mobile_preview_information_paragraph_1:
    "Opublikuj szablon. Pobierz aplikację Calendesk i podaj nazwę na pierwszym ekranie",
  plugins_mobile_preview_information_paragraph_2:
    "jako identyfikator firmy. Następnie zaloguj się na swoje konto użytkownika, podając swój adres e-mail oraz hasło.",
  plugins_mobile_preview_information_paragraph_3:
    "Po zalogowaniu aplikacje będzie się automatycznie odświeżać, po wprowadzonych tutaj zmianach.",
  plugins_mobile_preview_text_button: "Wyślij link do aplikacji",
  send_mobile_app_link_dialog_title: "Wyślij link do aplikacji",
  send_mobile_app_link_dialog_email_tab_title: "E-mail",
  send_mobile_app_link_dialog_email_tab_input_label:
    "Adres e-mail, na jaki mamy wysłać link do aplikacji",
  send_mobile_app_link_dialog_email_tab_submit_button: "Wyślij e-mail",
  send_mobile_app_link_dialog_sms_tab_title: "SMS",
  send_mobile_app_link_dialog_sms_tab_input_label:
    "Numer telefonu, na jaki mamy wysłać link do aplikacji",
  send_mobile_app_link_dialog_sms_tab_submit_button: "Wyślij SMS",
  publish_mobile_draft_dialog_title: "Aplikacja mobilna w trakcie publikowania",
  publish_mobile_draft_dialog_subtitle:
    "Może minąć kilka minut, zanim aplikacja będzie widoczna w internecie.",
  publish_mobile_draft_dialog_button: "Wróć do szkiców",
  mobile_home_form_title: "Główny widok",
  mobile_home_form_logo_label: "Zdjęcie w tle",
  mobile_home_form_logo_select_button: "Wybierz",
  mobile_home_form_slogan_label: "Nagłówek",
  mobile_home_form_signup_button: "Przycisk rejestracji",
  mobile_services_form_title: "Usługi",
  mobile_services_form_menu_services_label: "Zakładka",
  mobile_services_form_services_title: "Nagłówek",
  mobile_services_form_services_input_label: "Wyszukiwarka",
  mobile_services_form_services_search_empty_screen_subtitle_label:
    "Brak wyników",
  mobile_services_form_services_types_title_label: "Warianty usługi",
  mobile_services_form_cancel_booking_success_button_label: "Przycisk inne",
  mobile_services_form_logo_label: "Zdjęcie w tle",
  mobile_services_form_logo_select_button: "Wybierz",
  mobile_about_us_form_title: "O nas",
  mobile_about_us_form_location_title: "Nagłówek lokalizacji",
  mobile_about_us_form_location_info:
    "Adres ustawisz w głównym panelu Calendesk w Ustawienia / Twoja firma. Usunięcie adresu ukryje go również w aplikacji.",
  mobile_about_us_form_location_2_info:
    "Uzupełnij adres w głównym panelu Calendesk w Ustawienia / Twoja firma, aby pokazać lokalizację Twojej firmy w tej sekcji.",
  mobile_about_us_form_menu_about_us_label: "Zakładka",
  mobile_about_us_form_about_us_about_us_title_label: "Nagłówek dla opisu",
  mobile_about_us_form_contact_info:
    "Numer telefonu oraz godziny otwarcia ustawisz w panelu Calendesk w Ustawienia / Twoja firma. Jeśli chcesz ukryć powyższe sekcje, usuń ich zawartość.",
  mobile_about_us_form_employee_info:
    "Pracowników widocznych w tej sekcji możesz ustawić w panelu Calendesk w zakładce Pracownicy.",
  mobile_about_us_form_bookings_info:
    "Na podglądzie po prawej stronie widzisz przykładowe rezerwacje.",
  mobile_about_us_form_about_us_employees_title_label:
    "Nagłówek dla specjalistów",
  mobile_about_us_form_about_us_contact_title_label: "Nagłówek dla kontaktu",
  mobile_about_us_form_about_us_text_label: "Tekst",
  mobile_about_us_form_logo_label: "Zdjęcie w tle",
  mobile_about_us_form_logo_select_button: "Wybierz",
  mobile_bookings_form_title: "Rezerwacje",
  mobile_bookings_form_menu_bookings_label: "Zakładka",
  mobile_bookings_form_menu_active_bookings_label: "Aktywne rezerwacje",
  mobile_bookings_form_menu_previous_bookings_label: "Poprzednie rezerwacje",
  mobile_account_form_title: "Profil",
  mobile_account_form_menu_bookings_label: "Zakładka",
  mobile_account_editor_info_message:
    "Wybierz poniżej sekcję, którą chcesz edytować. Możesz pobrać aplikację mobilną dla klientów z @{google} lub @{apple} i zobaczyć rezultat zaraz po opublikowaniu.",
  mobile_account_editor_info_message_2:
    "Po pobraniu aplikacji, na pierwszym ekranie wpisz nazwę swojej firmy (@{company}).",
  mobile_account_editor_home_image_message:
    "Sugerowany rozmiar obrazka głównego to 600x800 pikseli.",
  mobile_account_editor_global_logo_image_message:
    "Sugerowany rozmiar logo to 600x300 pikseli.",

  // Payments
  add_payment_method_dialog_subtitle:
    "Wprowadź dane karty, którą będziesz wykorzystywać do płatności w Calendesk. Calendesk nie przechowuje danych Twojej karty, dane przekazywane są bezpiecznie przez system operatora płatności Stripe.",
  card_number: "Numer karty",
  date_of_expiry: "Data ważności",
  ccv: "CCV",
  card_stored_correctly: "Dane zostały zapisane poprawnie.",
  enter_card_data_error: "Wpisz poprawne dane karty.",

  // Packages and plans

  plans: "Plany",
  activate_plan: "Aktywuj plan",
  current_plan: "Aktualny plan",
  plan_valid_till: "ważny do",
  plans_headline: "Elastyczne ceny dopasowane do Twojej firmy",
  plans_headline_2:
    "Usprawnij proces rezerwacji i rozwijaj swój biznes dzięki nowoczesnym rozwiązaniom!",
  monthly_payment: "Płatność miesięczna",
  annual_payment: "Płatność roczna",
  for_free_forever: "za darmo na zawsze",
  monthly_no_vat: "miesięcznie",
  yearly_no_vat: "rocznie",
  once_no_vat: "jednorazowo",
  currently_used: "Aktualnie używasz",
  cancel_plan_generic_title:
    "Zanim podejmiesz decyzję, pamiętaj, że zawsze jesteśmy tu dla Ciebie! 💬",
  cancel_plan_generic_info:
    "Porozmawiaj z nami na czacie, aby znaleźć rozwiązanie najkorzystniejsze dla Ciebie 🤝 lub umów się z nami na bezpłatną konsultację online, podczas której odpowiemy na wszystkie Twoje pytania.",
  cancel_plan: "Potwierdzam anulowanie 😢",
  cancel_plan_1: "Ja oraz moi klienci stracimy dostęp do systemu rezerwacji.",
  cancel_plan_2: "Anuluj plan",
  lower_plan: "Przejdź na niższy plan",
  cancel_subscription_title: "Czy na pewno chcesz anulować subskrypcję? 😔",
  cancel_subscription_title_2:
    "Aktualnie korzystasz z bezpłatnego okresu próbnego. Twój plan automatycznie wygaśnie, jeśli nie sfinalizujesz zakupu.",
  cancel_subscription_subtitle_2: "Nie, zostaję! 😊",
  cancel_subscription_subtitle_3:
    "Nie możesz anulować planu podczas korzystania z okresu testowego.",
  cancel_subscription_text: "Twoja subskrypcja zakończy się z dniem",
  cancel_subscription_text_2:
    "Wszystkie płatne funkcje będą nadal dostępne do wykorzystania do końca okresu subskrypcji.",
  cancel_subscription_text_3: "Zamiast anulować, wybieram niższy plan.",
  cancel_subscription_text_4: "Plan @{plan} za jedyne @{price} (bez VAT).",

  plans_advantage_1: "Nieograniczona liczba pracowników",
  plans_advantage_2: "Panel administracyjny",
  plans_advantage_3: "System rezerwacji i kalendarz",
  plans_advantage_4: "Nieograniczona liczba klientów",
  plans_advantage_5: "Historia wizyt",
  plans_advantage_6: "Zasoby powiązane z rezerwacjami",
  plans_advantage_7: "Grafik pracy",
  plans_advantage_8: "Płatności online na etapie rezerwacji",
  plans_advantage_9: "Powiadomienia e-mail",
  plans_advantage_10: "Pakiet SMS na start - 50 sztuk",
  plans_advantage_11: "Pakiet SMS - 100 sztuk miesięcznie",
  plans_advantage_12: "Pakiet SMS - 200 sztuk miesięcznie",
  plans_advantage_13: "Wsparcie techniczne",
  plans_advantage_14: "Strona www (sub domena Calendesk)",
  plans_advantage_15: "Aplikacja mobilna dla pracowników",
  plans_advantage_16: "Aplikacja mobilna dla klientów",
  plans_advantage_17: "Niezależna aplikacja mobilna dla klientów",
  plans_advantage_18: "Dedykowany opiekun firmy",
  plans_advantage_19: "Polityka prywatności i regulamin",
  plans_advantage_20: "Strona www (własna domena)",
  plans_advantage_21: "Certyfikat SSL dla strony www",
  plans_advantage_22: "1 pracownik",
  plans_advantage_23: "Do 5 pracowników",
  plans_advantage_24: "Do 250 klientów",

  order_plan_summary_title: "Podsumowanie zamówienia",
  monthly_subscription: "Subskrypcja miesięczna",
  yearly_subscription: "Subskrypcja roczna",
  plan: "Plan",
  summary_payment: "Płatność",
  summary: "Razem",
  promo_code: "Kod rabatowy",
  promo_code_is_invalid: "Kod rabatowy wygasł lub jest nieprawidłowy",
  promo_code_is_valid: "Kod rabatowy został doliczony do zamówienia",
  continue: "Kontynuuj",
  continue_and_pay: "Kontynuuj i zapłać",
  payment_method_deleted_successfully: "Metoda płatności została usunięta.",
  payment_method_delete_failed:
    "Metoda płatności nie została usunięta, spróbuj ponownie.",
  subscription_set_successfully: "Subskrypcja ustawiona poprawnie.",
  subscription_cancelled_successfully: "Subskrypcja została anulowana:(.",

  // Invoices

  payment_method: "Metoda płatności",
  add_payment_method: "Dodaj metodę płatności",
  change_payment_method: "Zmień metodę płatności",
  delete_payment_method: "Usuń",
  my_invoices: "Moje faktury",
  payment_history: "Historia płatności",
  download_pdf: "Pobierz PDF",
  invoice_data_updated_successfully:
    "Dane do faktury zostały zaktualizowane poprawnie",
  invoice_data_update_failed:
    "Dane do faktury nie zostały zaktualizowane, spróbuj ponownie.",
  tenant: "Firma",
  created: "Potwierdzenie",
  deleted: "Usunięcie",
  updated: "Zmiana",
  reminder: "Przypomnienie",
  invoice_number: "Numer faktury",
  invoice_issued_at: "Data wystawienia",
  invoice_total: "Kwota",
  invoice_vat: "VAT",
  invoice_description: "Opis",
  buy_package_success:
    "Pakiet został zakupiony. Może upłynąć kilka sekund do czasu aktywacji.",
  buy_package_failed:
    "Wystapił problem podczas zakupu. Upewnij się, że Twoja karta działa i akceptuje płatności.",
  buy_package_confirmation_button: "Kup pakiet",
  buy_package_confirmation_question:
    "Czy na pewno chcesz zakupić pakiet? Twoja karta zostanie obciążona.",
  incorrect_time: "Niepoprawna godzina",
  web_editor_color_1_label: "Kolor przycisku zatwierdzania",
  web_editor_color_2_label: "Filtr",
  web_editor_color_3_label: "Kolor przycisku anulowania",
  web_editor_color_4_label: "Kolor animacji ładowania",
  web_editor_color_5_label: "Kolor tła",
  web_editor_color_6_label: "Kolor tekstu",
  web_editor_color_7_label: "Jaśniejszy kolor tekstu",
  web_editor_color_8_label: "Kolor tła",
  web_editor_color_9_label: "Kolor linków",
  web_editor_color_10_label: "Kolor akcentów",
  web_editor_color_11_label: "Kolor tła",
  web_editor_color_12_label: "Kolor tła",
  web_editor_color_13_label: "Kolor suwaka",
  web_editor_color_14_label: "Kolor tła",
  web_editor_color_15_label: "Kolor tekstu",
  web_editor_color_16_label: "Kolor tła",
  web_editor_color_17_label: "Kolor ikon",
  web_editor_color_18_label: "Kolor linków",
  contact_data_info:
    "Dane kontaktowe ułatwi znalezienie Twojej firmy Twoim klientom, jeśli np. korzystasz z aplikacji mobilnej.",
  service_info_7:
    "Jeśli włączysz opcję przedpłaty, Twoi klienci będą musieli dokonać płatności, zanim rezerwacja zostanie zaakceptowana. Do tego czasu zablokujemy kalendarz, aby umożliwić klientom dokończenie płatności. Nieopłacone rezerwacje będą usuwane w czasie, który możesz ustawić poniżej. Kalendarz zostanie odblokowany po usunięciu nieopłaconych rezerwacji.",
  awaiting_booking_payment_time: "Czas na opłacenie rezerwacji",
  service_description:
    "Krótki opis usługi, który wyświetlimy podczas wyboru usługi.",
  company_data_saved: "Twoje dane firmowe zostały zapisane poprawnie",
  company_data_failed:
    "Przepraszamy, wystąpił błąd. Twoje dane firmowe nie zostały zapisane, spróbuj ponownie.",
  closed: "Zamknięte",
  not_working: "Nie pracuje",
  generate_regulations: "Wygeneruj",
  generate_regulations_success:
    "Pomyślnie wygenerowano regulamin i politykę prywatności",
  generate_regulations_fail:
    "Wystąpił problem podczas generowania regulaminu i polityki prywatności, spróbuj ponownie.",
  generate_regulations_info:
    "Wygeneruj regulamin i politykę prywatności, które mogą być wykorzystane na Twojej stronie internetowej w Calendesk. Te dokumenty są stworzone dla firm, które zarządzają rezerwacjami online i przetwarzają płatności. Wpisz pełne dane Twojej firmy i podaj informacje o świadczonych usługach (zwróć uwagę na odmianę nazw Twoich usług – deklinacja), a potem kliknij „Wygeneruj”. Dodaj dokumenty na swojej stronie internetowej za pomocą kreatora, wybierając sekcje z kategorii „Inne”. Pamiętaj, że wygenerowane dokumenty są tylko przykładowe i ostateczną wersję powinieneś skonsultować z prawnikiem. Calendesk nie ponosi odpowiedzialności za treść Twoich dokumentów.",
  regulations_and_terms_title: "Generator dokumentów",
  full_company_name: "Pełna nazwa Twojej firmy",
  full_company_name_example: "np. Pro&More Sp. z o.o.",
  full_company_address: "Adres rejestracji Twojej firmy",
  full_company_address_example: "np. ul. Marszałkowska 1, 00-001 Warszawa",
  regulations_data_services_1_label: "Dla jakich usług tworzysz regulamin?",
  regulations_data_services_1_example:
    "np. prawniczych, psychologicznych, kosmetycznych",
  regulations_data_services_2_label: "Jakie usługi świadczysz?",
  regulations_data_services_2_example:
    "np. prawnicze, psychologiczne, kosmetyczne",
  regulations_data_company_url_label: "Adres URL strony lub kalendarza",
  regulations_data_company_email_label: "Adres e-mail do kontaktu z firmą",
  regulations_data_company_email_example: "np. kontakt@mojastrona.pl",
  regulations_data_company_phone_label: "Telefon do kontaktu z firmą",
  regulations_data_company_phone_example: "np. +48500123456",
  regulations_data_date_label: "Regulamin obowiązuje od dnia",
  example_short: "np.",
  privacy_policy_description_too_long:
    "Tekst polityki prywatności jest za długi (200 000 znaków).",
  regulations_description_too_long:
    "Tekst regulaminu jest za długi (200 000 znaków).",
  copy_data: "Przekopiuj dane",
  no_employees_info:
    "Nie masz jeszcze pracowników, kliknij poniżej, aby dodać pierwszego.",
  no_users_info:
    "Nie masz jeszcze klientów, kliknij poniżej, aby dodać pierwszego.",
  no_bookings_info:
    "Nie masz jeszcze rezerwacji, kliknij poniżej, aby dodać pierwszą.",
  no_services_info:
    "Nie masz jeszcze usług, kliknij poniżej, aby dodać pierwszą.",
  no_categories_info:
    "Nie masz jeszcze kategorii, kliknij poniżej, aby dodać pierwszą.",
  no_newsletter_info:
    "Nie masz jeszcze adresów e-mail w newsletterze, kliknij poniżej, aby dodać pierwszy.",
  employee_position: "Stanowisko",
  employee_position_example: "np. Manager",
  employee_note_description:
    "Tutaj możesz pochwalić się swoim pracownikiem, informacje te pokażemy na stronie www oraz w aplikacji mobilnej.",
  employee_permission_info:
    "Możesz nadać swojemu pracownikowi uprawnienia i pozwolić mu, aby logował się do systemu.",
  employee_permission_employee: "Pracownik (zaufany)",
  employee_permission_employee_basic: "Pracownik (ograniczony)",
  employee_permission_manager: "Manager",
  employee_permission_admin: "Administrator",
  employee_permission_employee_info:
    "Pracownicy z tym uprawnieniem mają pełny wgląd w kalendarz innych pracowników. Mogą swobodnie zmieniać rezerwacje pomiędzy innymi pracownikami. Mają pełen dostęp do kont wszystkich klientów oraz ich rezerwacji i płatności. Nie mają dostępu do zarządzania kontami innych pracowników. Nie mogą edytować strony www oraz aplikacji mobilnej. Nie mogą zmieniać głównych ustawień systemu.",
  employee_permission_employee_basic_info:
    "Pracownicy z tym uprawnieniem mają wgląd tylko w swój kalendarz rezerwacji. Mogą wyświetlać, dodawać, edytować i usuwać spotkania tylko w obrębie swojego konta. Mogą wyszukiwać klientów podczas dodawania rezerwacji i widzieć ich podstawowe dane. Nie mogą usuwać oraz edytować danych klientów. Nie mają dostępu do danych pozostałych pracowników oraz ich rezerwacji.",
  employee_permission_manager_info:
    "Konta z tym uprawnieniem mają uprawnienia takie jak Pracownik (zaufany). Dodatkowo mogą zarządzać innymi pracownikami, usługami, kategoriami, stroną www i aplikacją mobilną. Nie mogą zarządzać planem Calendesk oraz dodatkowymi pakietami usług, nie mają dostępu do integracji z systemem płatności.",
  employee_permission_admin_info:
    "Konta administratorów posiadają pełne uprawnienia do wszystkich funkcji w serwisie.",
  choose_role: "Wybierz funkcję",
  employee_app_info:
    "Twoi pracownicy mogą mieć podgląd do kalendarza, klientów w specjalnie dla nich przygotowanej aplikacji mobilnej dostępnej na systemy iOS oraz Android. Zaznacz, czy chcesz, abyśmy wysłali wiadomość e-mail z linkiem do pobrania aplikacji.",
  employee_public_checkbox:
    "Pracownik jest widoczny dla klientów i przyjmuje rezerwacje przez kalendarz online",
  employee_public_info:
    "Możesz uzupełnić dodatkowe (opcjonalne) informacje o pracowniku. Dane te będą mogły być później wykorzystane w specjalnych sekcjach np. na stronie www lub w aplikacji mobilnej.",
  employee_working_hours: "Grafik dostępności",
  employee_working_hours_info:
    "Poniższy grafik dostępności zostanie wykorzystany dla usług, które korzystają z dostępności pracowników.",
  color_in_calendar: "Kolor w kalendarzu",
  delete_success: "Poprawnie usunięto.",
  update_success: "Poprawnie zapisano.",
  web_editor_color_19_label: "Kolor tekstu",
  skip: "Pomiń",
  configuration: "Konfiguracja",
  start_configuration: "Zaczynajmy",
  fill: "Uzupełnij",
  filled: "Uzupełnione",
  create: "Stwórz",
  enable: "Włącz",
  disable: "Wyłącz",
  mobile_home_form_go_to_login_label: "Przejdź do logowania",
  accept_terms_and_conditions_1: "Rejestrując się, zgadzam się na",
  accept_terms_and_conditions_2: "Warunki użytkowania",
  accept_terms_and_conditions_3: "i potwierdzam zapoznanie się z",
  accept_terms_and_conditions_4: "Oświadczeniem dotyczącym prywatności.",
  change_currency: "Zmień walutę",
  change_currency_info: "Zmiana waluty dla wszystkich usług.",
  web_editor_color_20_label: "Kolor sloganu",
  copy: "Kopiuj",
  copied_to_clipboard: "Skopiowano do schowka",
  stripe: "Stripe",
  stripe_description:
    "Zaawansowany operator płatności, który pozwoli Ci przetwarzać płatności od klientów w czasie rzeczywistym.",
  go_to_stripe: "Przejdź do Stripe",
  stripe_info_step_1:
    "Kliknij „Utwórz konto Stripe”, aby przejść do Stripe i utworzyć konto. Po ukończeniu, Twoje konto płatności, zostanie połączone z Calendesk. Calendesk nie będzie miał dostępu do Twojego konta. Stripe jest niezależnym operatorem płatności. Połączenie konta Stripe z Calendesk umożliwi tworzenie płatności w Twoim imieniu, na Twojej stronie www oraz w aplikacji mobilnej.",
  stripe_info_step_2:
    "Przejdź do Stripe, aby ukończyć proces zakładania konta.",
  stripe_info_step_3:
    "Zakładnie konta w Stripe zostało ukończone. Stripe weryfikuje przesłane informacje.",
  stripe_info_step_4:
    "Twoje konto Stripe jest podłączone poprawnie. Możesz przyjmować płatności od klientów. Pamiętaj, aby przy usługach wymagających przedpłaty zaznaczyć opcję „Przedpłata”.",
  stripe_title_step_1: "Utwórz konto Stripe",
  stripe_title_step_2: "Tworzenie konta w trakcie",
  stripe_title_step_3: "Weryfikacja konta",
  stripe_title_step_4: "Konto podłączone",
  allow_online_payments: "Płatności",
  allow_online_payment_2: "Włącz płatności online dla tej usługi.",
  payment_required: "Przedpłata",
  payment_required_2: "Wymagaj przedpłaty przed potwierdzeniem rezerwacji.",
  service_payments: "Ustawienia płatności",
  paid_online: "Płatność",
  paid: "Zapłacone",
  not_paid: "Niezapłacone",
  navbar_element_form_home_label: "Strona główna",
  user_payments: "Płatności",
  past_due_subscription_title: "Twoje konto zostało tymczasowo zablokowane 😵",
  past_due_subscription_subtitle_1:
    "Z przykrością informujemy, że Twoje konto w Calendesk zostało tymczasowo zablokowane z powodu zaległości w płatnościach. Kilkakrotnie próbowaliśmy pobrać środki z Twojej karty, jednak bezskutecznie. Dlatego też zachęcamy Cię do sprawdzenia swojej karty i spróbowania ponowienia płatności.",
  past_due_subscription_subtitle_2:
    "Jeśli chcesz wymienić kartę, możesz to zrobić poniżej. Po dokonaniu płatności Twoje konto zostanie automatycznie odblokowane. W razie jakichkolwiek pytań lub wątpliwości zapraszamy do kontaktu z nami poprzez czat. Chętnie rozwiejemy Twoje wątpliwości i pomożemy rozwiązać problem z płatnościami.",
  past_due_subscription_subtitle_3:
    "Z przykrością informujemy, że konto w Calendesk zostało tymczasowo zablokowane z powodu zaległości w płatnościach. Prosimy o pilny kontakt z administratorem konta lub osobą odpowiedzialną za płatności.",
  retry_past_due_invoice_title: "Opłać zaległość",
  past_due_subscription_title_2: "Na Twoim koncie występuje zaległa płatność",
  past_due_subscription_subtitle_4:
    "Prosimy o dokonanie opłaty, aby uniknąć automatycznej blokady konta.",
  past_due_subscription_subtitle_5:
    "Podczas gdy na Twoim koncie występują nieuregulowane płatności, zmiana lub anulowanie subskrypcji nie są możliwe.",
  past_due_subscription_subtitle_6:
    "Jeśli chcesz wymienić kartę, możesz to zrobić poniżej. W razie jakichkolwiek pytań lub wątpliwości zapraszamy do kontaktu z nami poprzez czat. Chętnie rozwiejemy Twoje wątpliwości i pomożemy rozwiązać problem z płatnościami.",
  select_plan: "Wybierz plan",
  subscription_will_not_renew_info: "Twój plan nie odnowi się automatycznie",
  no_email_for_password_reset_info:
    "Nie możesz ustawić hasła, jeśli wybrany użytkownik nie ma podanego adresu email.",
};
